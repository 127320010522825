import './App.css';
import MainPage from './pages/mainPage';
import React from "react";



const App = () => {
  return (
    <div>
      <MainPage />   
    </div>
  );
}

export default App;
